@font-face {
  font-family: "Open Sans";
  src: url('./fonts/Open_Sans/static/OpenSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url('./fonts/Open_Sans/static/OpenSans-Bold.ttf');
  font-weight: 500;
  font-style: normal;
}