@import './fonts.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  /* table-of-contents scroll */
  h2, h3 {
    scroll-margin-top: 16px;
  }
  h1 {
    @apply text-4xl sm:text-6xl font-extrabold;
  }
  h2 {
    @apply text-3xl sm:text-5xl font-extrabold sm:leading-[1.2];
  }
  h3 {
    @apply text-lg font-bold;
  }
  h4 {
    @apply font-light;
  }
  /* Safari-only */
  @supports (-webkit-hyphens:none) {
      h2, h3 {
          padding-top: 16px;
          margin-top: -16px;
      }
  }
}
@layer components {
  .btn-default {
    @apply bg-primary-200 hover:bg-primary-200 text-black font-bold;
  }
  .btn-outlined {
    @apply border-2 border-primary-200 text-black hover:bg-primary-200 hover:text-white;  
  }
}

footer .component-link-list { 
  @apply my-4;
}

/* SECTION HERO */
.componentHeroSmall {
  @apply bg-gray-200;
}
/* SECTION HEADLINE */
.componentSectionHeadline {
  @apply mt-12 mb-6;
}

/* SECTION TEXT */
.componentText {
  @apply mt-6 mb-12;
}

/* Custom scrollbar styles using Tailwind's colors and sizes */

.scrollable-div::-webkit-scrollbar {
  width: 4px; /* Adjust the width of the scrollbar */
  height: 4px;
}

.scrollable-div::-webkit-scrollbar-track {
  @apply bg-primary-100 bg-opacity-0;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #6b7280; /* Darker gray for the thumb using Tailwind color */
  border-radius: 4px; /* Rounded corners for the scrollbar */
  border: 0px solid #f3f4f6; /* Matching the track background for seamless design */
}

.scrollable-div:hover::-webkit-scrollbar-thumb {
  background-color: #4b5563; /* Even darker on hover */
}